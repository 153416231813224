/**
 * The FlashAlert component
 */
import React, { useState, useEffect } from 'react';
import { Alert } from 'reactstrap';
/**
 * Enhancer for reactstrap Alert
 * - adds timeout property (in seconds) to define the timeout to close the Alert
 */
 const FlashAlert = (props) => {
  const [timeout, setTimeout] = useState(false)
  const [visible, setVisible] = useState(true)

   const onDismiss = () => {
   setVisible(false)
  }

  /**
   * Sets the timeout on component mount
   */
 useEffect(() => {
   if (timeout) {
      setTimeout(() => {
        onDismiss()
      }, timeout * 1000);
    }
 }, [timeout])
  /**
   * Dismisses the flash alert by changing its visibility
   */
 
  /**
   * Renders the Alert component
   */
    const { children, color } = props;
    return (
      <Alert color={color} isOpen={visible} toggle={onDismiss}>
        {children}
      </Alert>
    );
}

export default FlashAlert;