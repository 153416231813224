/*
Replace
dishRecipeStepIngredient with your resource Name :) Take care about different writings!
/!\ DishRecipeStepIngredient dishRecipeStepIngredient DishRecipeStepIngredients dishRecipeStepIngredients /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const dishRecipeStepIngredientSlice = createSlice({
  name: "dishRecipeStepIngredients",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
  },
});

export const {
  list: listDishRecipeStepIngredients,
  show: showDishRecipeStepIngredient,
  create: createDishRecipeStepIngredient,
  update: updateDishRecipeStepIngredient,
} = dishRecipeStepIngredientSlice.actions;

export const actions = dishRecipeStepIngredientSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const dishRecipeStepIngredients = (state) =>
  state.dishRecipeStepIngredients.idsList.map(
    (id) => state.dishRecipeStepIngredients.dictionary[id]
  );
export const dishRecipeStepIngredient = (state) =>
  state.dishRecipeStepIngredients.showId &&
  state.dishRecipeStepIngredients.dictionary[state.dishRecipeStepIngredients.showId];
export const dishRecipeStepIngredientAttributes = (state) =>
  dishRecipeStepIngredient(state)?.attributes;
export const dishRecipeStepIngredientRelationships = (state) =>
  dishRecipeStepIngredient(state)?.relationships;
export const dishRecipeStepIngredientId = (state) =>
  state.dishRecipeStepIngredients.showId;

export const getDishRecipeStepIngredient = createSelector(
  (state) => state.dishRecipeStepIngredients.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getDishRecipeStepIngredientAttributes = createSelector(
  (state) => state.dishRecipeStepIngredients.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getDishRecipeStepIngredientRelationships = createSelector(
  (state) => state.dishRecipeStepIngredients.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getDishRecipeStepIngredients = createSelector(
  (state) => state.dishRecipeStepIngredients.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export default dishRecipeStepIngredientSlice.reducer;
