/** ----- buildHeaders.js -----
 *  File: buildHeaders.js
 *
 *  Purpose: providing helper functions to set headers for api requests
 *
 *-----------------------------*/
import { getTokenCookies } from "./tokenCookies";
function buildHeaders(user) {
  let userTokens = getTokenCookies();
  console.log("getting cookies", userTokens);
  if (user && userTokens && userTokens["access-token"]) {
    return {
      "Content-Type": "application/json",
      "access-token": userTokens["access-token"],
      client: userTokens.client,
      uid: userTokens.uid,
      "token-type": "Bearer",
    };
  } else if (user && user.auth_token) {
    return {
      "Content-Type": "application/json",
      "access-token": user.auth_token,
      client: user.client_id,
      uid: user.uid,
      "token-type": "Bearer",
    };
  }
  return {
    "Content-Type": "application/json",
  };
}

export default buildHeaders;
