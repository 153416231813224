/**
 *  The Private Route container
 */
import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { getTokenCookies } from "../../utils/tokenCookies";
/**
 * Stateless component to check user authentication before redirecting to a page
 * @param {*} param0
 */
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const { account } = rest;
      var validToken = false;
      var userTokens = getTokenCookies();
      if (userTokens) {
        validToken = new Date(parseInt(userTokens.expiry) * 1000) > new Date();
      }
      return account.signedin && validToken ? (
        <Component {...props} />
      ) : (
        <Redirect to={`/account/login?redirect=${props.location.pathname}`} />
      );
    }}
  />
);

/**
 * Selects props from the store and passes them to the component
 * @param {Object} state the store
 */
const mapStateToProps = (state) => ({
  account: state.professionalAccount,
});

/** export the redux connected component and add a frontload call */
export default connect(mapStateToProps, null)(PrivateRoute);
