/**
 * Window dataLayer Helper
 */
import { isServer } from "../redux/store";

const dataLayer = () => {
  return !isServer ? window.dataLayer || [] : [];
};

const addEvent = (event, action, label = "", value = 0) => {
  const dl = dataLayer();
  dl.push({
    event,
    action,
    label,
    value,
  });
};

const addEventWithOrigin = (
  event,
  eventOrigin,
  action,
  label = "",
  value = 0
) => {
  const dl = dataLayer();
  dl.push({
    event,
    eventOrigin,
    action,
    label,
    value,
  });
};

const addTransactionEvent = (transaction, from) => {
  const dl = dataLayer();
  dl.push({
    event: "transaction",
    action: transaction.event_name,
    label: transaction.label || transaction.option,
    value: transaction.amount,
    to: transaction.to,
    from,
  });
};

const addRedirectEvent = (action, label, value = 0) => {
  const dl = dataLayer();
  dl.push({
    event: "redirect",
    action,
    label,
    value,
  });
};

const addSuccessEvent = (action, label, value = 0) => {
  const dl = dataLayer();
  dl.push({
    event: "success",
    action,
    label,
    value,
  });
};

const addErrorEvent = (action, label, value = 0) => {
  const dl = dataLayer();
  dl.push({
    event: "error",
    action,
    label,
    value,
  });
};

// TODO: why is  this empty? Is it needed somewhere?
const addPurchaseEvent = () => {};

/** exports all actions as default */
const actions = {
  addRedirectEvent,
  addSuccessEvent,
  addErrorEvent,
  addTransactionEvent,
  addPurchaseEvent,
  addEvent,
  addEventWithOrigin,
};

export default actions;
