/**
 * Used for access control management
 * @param {Object} props the props passed to the page.
 */
const AccessControl = (props) => {
  const { children, role, module, right, restrictedComponent } = props;
  let accessRestricted = true;
  let roleRights = {};

  switch (role) {
    case "cook":
      // roles for chef
      roleRights = {
        academy: {
          read: true,
          index: true,
        },
        menuPlanner: {
          read: true,
          index: true,
          update: false,
          create: false,
        },
        profile: {
          read: true,
          update: true,
          index: true,
        },
        userManagement: {
          read: false,
          index: false,
          update: false,
        },
        locations: {
          read: false,
          update: false,
          index: false,
        },
      };
      break;
    case "coaching":
      // roles for coaching
      roleRights = {
        academy: {
          read: true,
          index: true,
        },
        menuPlanner: {
          read: false,
          index: false,
          update: false,
          create: false,
        },
        profile: {
          read: true,
          update: true,
          index: true,
        },
        userManagement: {
          read: false,
          index: false,
          update: false,
        },
        locations: {
          read: false,
          update: false,
          index: false,
        },
      };
      break;
    case "unit_manager":
      // roles for manager
      roleRights = {
        academy: {
          read: true,
          index: true,
        },
        menuPlanner: {
          read: true,
          index: true,
          update: true,
          create: true,
        },
        profile: {
          read: true,
          update: true,
          index: true,
        },
        userManagement: {
          read: true,
          index: true,
          update: true,
        },
        locations: {
          read: true,
          update: true,
          index: true,
        },
      };
      break;
    case "unit_manager_light":
      // roles for manager light
      roleRights = {
        academy: {
          read: true,
          index: true,
        },
        menuPlanner: {
          read: true,
          index: true,
          update: true,
          create: false,
        },
        profile: {
          read: true,
          update: true,
          index: true,
        },
        userManagement: {
          read: true,
          index: true,
          update: true,
        },
        locations: {
          read: true,
          update: true,
          index: true,
        },
      };
      break;
    case "client_manager":
      // roles for client manager
      roleRights = {
        academy: {
          read: true,
          index: true,
        },
        menuPlanner: {
          read: true,
          index: true,
          update: true,
          create: true,
        },
        profile: {
          read: true,
          update: true,
          index: true,
        },
        userManagement: {
          read: true,
          index: true,
          update: true,
        },
        locations: {
          read: true,
          update: true,
          index: true,
        },
      };
      break;
    case "super_admin":
      // roles for super admin
      roleRights = {
        academy: {
          read: true,
          index: true,
        },
        menuPlanner: {
          read: true,
          index: true,
          update: true,
          create: true,
        },
        profile: {
          read: true,
          update: true,
          index: true,
        },
        userManagement: {
          read: true,
          index: true,
          update: true,
        },
        locations: {
          read: true,
          update: true,
          index: true,
        },
      };
      break;
    default:
      // roles any other role
      roleRights = {
        academy: {
          read: true,
          index: true,
        },
        menuPlanner: {
          read: false,
          index: false,
          update: false,
        },
        profile: {
          read: true,
          update: true,
          index: true,
        },
        userManagement: {
          read: false,
          index: false,
          update: false,
        },
        locations: {
          read: false,
          update: false,
          index: false,
        },
      };
      break;
  }

  if (roleRights[module] && roleRights[module][right]) accessRestricted = false;

  return accessRestricted ? restrictedComponent || null : children;
};
export default AccessControl;
