/**
 * The Root container
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import { StickyContainer } from "react-sticky";
import Navbar from "../components/Navbar";
import OfflineBar from "../components/OfflineBar";
import Loadable from "react-loadable";
import FourOFour from "./FourOFour";
import PrivateRoute from "../components/PrivateRoute";
import UserInvite from "./UserInvite";
import UserEdit from "./UserEdit";
import Footer from "../components/Footer/Footer";

/**
 * Loadable import for Users container
 */
const Users = Loadable({
  loader: () => import(/* webpackChunkName: "Users" */ "./Users"),
  loading: () => null,
  modules: ["Users"],
});
/**
/**
 * Loadable import for Courses container
 */
const Courses = Loadable({
  loader: () => import(/* webpackChunkName: "Courses" */ "./Courses"),
  loading: () => null,
  modules: ["Courses"],
});
/**
 * Loadable import for Lesson container
 */
const Lesson = Loadable({
  loader: () => import(/* webpackChunkName: "Lesson" */ "./Lesson"),
  loading: () => null,
  modules: ["Lesson"],
});
/**
 * Loadable import for UserDetails container
 */
const UserDetails = Loadable({
  loader: () => import(/* webpackChunkName: "UserDetails" */ "./UserDetails"),
  loading: () => null,
  modules: ["UserDetails"],
});
/**
/**
 * Loadable import for AllSteps container
 */
const AllSteps = Loadable({
  loader: () => import(/* webpackChunkName: "UserDetails" */ "./AllSteps"),
  loading: () => null,
  modules: ["AllSteps"],
});
/**
 * Loadable import for RecipeSteps container
 */
const RecipeSteps = Loadable({
  loader: () => import(/* webpackChunkName: "UserDetails" */ "./RecipeSteps"),
  loading: () => null,
  modules: ["RecipeSteps"],
});
/**
 * Loadable import for Dashboard container
 */
const Dashboard = Loadable({
  loader: () => import(/* webpackChunkName: "Dashboard" */ "./Dashboard"),
  loading: () => null,
  modules: ["Dashboard"],
});
/**
 * Loadable import for Locations container
 */
const Locations = Loadable({
  loader: () => import(/* webpackChunkName: "Locations" */ "./Locations"),
  loading: () => null,
  modules: ["Locations"],
});
/**
 * Loadable import for MenuPlanning container
 */
const MenuPlanner = Loadable({
  loader: () => import(/* webpackChunkName: "MenuPlanning" */ "./MenuPlanner"),
  loading: () => null,
  modules: ["MenuPlanner"],
});
/**
 * Loadable import for MenuPlanning container
 */
const MenuDishPlanner = Loadable({
  loader: () =>
    import(/* webpackChunkName: "MenuDishPlanner" */ "./MenuDishPlanner"),
  loading: () => null,
  modules: ["MenuDishPlanner"],
});
/**
 * Loadable import for Account container
 */
const Account = Loadable({
  loader: () => import(/* webpackChunkName: "Account" */ "./Account"),
  loading: () => null,
  modules: ["Account"],
});

const UserProfile = Loadable({
  loader: () => import(/* webpackChunkName: "UserProfile" */ "./UserProfile"),
  loading: () => null,
  modules: ["UserProfile"],
});

const RecipeDetails = Loadable({
  loader: () =>
    import(/* webpackChunkName: "RecipeDetails" */ "./RecipeDetails"),
  loading: () => null,
  modules: ["RecipeDetails"],
});

const DishPreview = Loadable({
  loader: () =>
    import(/* webpackChunkName: "DishPreview" */ "./DishPreview"),
  loading: () => null,
  modules: ["DishPreview"],
});

// DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
// new scaffolded loadable imports

/**
 * The Root container used as entry point for the react-router.
 * All routes are lazy-loaded with Loadable.
 */
class Root extends Component {
  render() {
    return (
      <StickyContainer id="Root" className="root">
        <OfflineBar />
        <Navbar />
        <div id="content">
          <Switch>
            <PrivateRoute path={"/"} exact component={Dashboard} />
            <PrivateRoute exact path="/users" component={Users} />
            <PrivateRoute exact path="/users/invite" component={UserInvite} />
            <PrivateRoute exact path={"/users/:id"} component={UserDetails} />
            <PrivateRoute exact path={"/users/:id/edit"} component={UserEdit} />
            <Route path={"/account/login"} component={Account} />
            <Route path={"/account/register"} component={Account} />
            <Route path={"/account/recover"} component={Account} />
            <Route path={"/account/reset"} component={Account} />
            <PrivateRoute path={"/profile"} component={UserProfile} />
            <PrivateRoute exact path={"/locations"} component={Locations} />

            {/* MENU PLANNER PATHS */}
            <PrivateRoute
              exact
              path={
                "/locations/:location_id/menu-planner/:menu_dish_id/recipes/:master_recipe_id/basisrecipe/:recipe"
              }
              component={RecipeDetails}
            />
            <PrivateRoute
              exact
              path={
                "/locations/:location_id/menu-planner/:menu_dish_id/recipes/:master_recipe_id/basisrecipe/:recipe/recipe-steps"
              }
              component={RecipeSteps}
            />
            <PrivateRoute
              exact
              path={
                "/locations/:location_id/menu-planner/:menu_dish_id/recipes/:master_recipe_id/basisrecipe/:nested_recipe_id/:recipe/recipe-steps"
              }
              component={RecipeSteps}
            />
            <PrivateRoute
              exact
              path={
                "/locations/:location_id/menu-planner/:menu_dish_id/recipes/:master_recipe_id/basisrecipe/:nested_recipe_id/:recipe"
              }
              component={RecipeDetails}
            />
            <PrivateRoute
              exact
              path={
                "/locations/:location_id/menu-planner/:menu_dish_id/recipes/:recipe/overview"
              }
              component={RecipeDetails}
            />
            <PrivateRoute
              exact
              path={
                "/locations/:location_id/menu-planner/:menu_dish_id/recipes/:recipe"
              }
              component={RecipeDetails}
            />
            <PrivateRoute
              exact
              path={"/locations/:location_id/menu-planner/"}
              component={MenuPlanner}
            />
            <PrivateRoute
              exact
              path={"/locations/:location_id/menu-planner/:dish/recipe-steps"}
              component={RecipeSteps}
            />
            <PrivateRoute
              path={
                "/locations/:location_id/menu-planner/:menu_dish_id/recipes/:recipe/recipe-steps"
              }
              component={RecipeSteps}
            />

            <PrivateRoute
              exact
              path={
                "/locations/:location_id/menu-planner/:menu_dish_id/all-steps"
              }
              component={AllSteps}
            />
            <PrivateRoute
              path={
                "/locations/:location_id/menu-planner/:menu_dish_id/recipes/:recipe/all-steps"
              }
              component={AllSteps}
            />
            <PrivateRoute
              exact
              path={
                "/locations/:location_id/menu-planner/:timeslot/:day/daily/:counter/menu-dish-planner"
              }
              component={MenuDishPlanner}
            />
            <PrivateRoute
              exact
              path={
                "/locations/:location_id/menu-planner/:timeslot/:day/daily/:counter/menu-dish-planner/:menu_dish_id/edit"
              }
              component={MenuDishPlanner}
            />
           <PrivateRoute
              exact
              path={
                "/locations/:location_id/menu-planner/:timeslot/counter/:counter/:day/dish/:dish/recipes/:master_recipe_id/basisrecipe/:recipe/menu-dish-planner/new"
              }
              component={DishPreview}
            />
            <PrivateRoute
              exact
              path={
                "/locations/:location_id/menu-planner/:timeslot/counter/:counter/:day/dish/:dish/recipes/:master_recipe_id/basisrecipe/:recipe/menu-dish-planner/:menu_dish_id/edit"
              }
              component={DishPreview}
            />
            <PrivateRoute
              exact
              path={
                "/locations/:location_id/menu-planner/:timeslot/counter/:counter/:day/dish/:dish/recipes/:master_recipe_id/basisrecipe/:nested_recipe_id/:recipe/menu-dish-planner/new"
              }
              component={DishPreview}
            />
            <PrivateRoute
              exact
              path={
                "/locations/:location_id/menu-planner/:timeslot/counter/:counter/:day/dish/:dish/recipes/:master_recipe_id/basisrecipe/:nested_recipe_id/:recipe/menu-dish-planner/:menu_dish_id/edit"
              }
              component={DishPreview}
            />
            <PrivateRoute
              exact
              path={
                "/locations/:location_id/menu-planner/:timeslot/counter/:counter/:day/dish/:dish/recipes/:recipe/menu-dish-planner/new"
              }
              component={DishPreview}
            />
            <PrivateRoute
              exact
              path={
                "/locations/:location_id/menu-planner/:timeslot/counter/:counter/:day/dish/:dish/recipes/:recipe/menu-dish-planner/:menu_dish_id/edit"
              }
              component={DishPreview}
            />
            <PrivateRoute
              exact
              path={
                "/locations/:location_id/menu-planner/:timeslot/counter/:counter/:day/dish/:dish/menu-dish-planner/new"
              }
              component={DishPreview}
            />
            <PrivateRoute
              exact
              path={
                "/locations/:location_id/menu-planner/:timeslot/counter/:counter/:day/dish/:dish/menu-dish-planner/:menu_dish_id/edit"
              }
              component={DishPreview}
            />
            <PrivateRoute path={"/dashboard"} component={Dashboard} />
            {/* ACADEMY PATHS */}
            <PrivateRoute exact path={"/academy/courses"} component={Courses} />
            <PrivateRoute
              exact
              path={"/academy/courses/course-:course/lesson-:lesson"}
              component={Lesson}
            />

            {/* // DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT */}
            {/* new routes */}
            <Route component={FourOFour} />
          </Switch>
        </div>
        <Footer />
      </StickyContainer>
    );
  }
}

/**
 * Selects props from the store and passes them to the component
 * @param {Object} state the store
 */
const mapStateToProps = (state) => ({});
/**
 * Binds dispatch method to the actions passed as props to the component
 */
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

/** export the redux connected component and add a frontload call */
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Root));
