/**
 * The navbar container
 */
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { connect } from "react-redux";
// import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import { setLanguage } from "redux-i18n";
import FlashAlert from "../FlashAlert";
import PropTypes from "prop-types";
import avatar from "../../assets/images/avatar-placeholder.png";
import ogLogo from "../../assets/images/OG_logo_frame.svg";
import avatarRetina from "../../assets/images/avatar-placeholder@2x.png";
import accountActions from "../../redux/professional_account";
import AccessControl from "../../components/AccessControl";
import { getClientAttributes, showClient } from "../../redux/clients";
import { useSelector } from "react-redux";
const { REACT_APP_CLIENT_ID } = process.env;

/**
 * Selects props from the store and passes them to the component
 * @param {Object} state the store
 */
const mapStateToProps = (state) => ({
  alertMessage: state.notifications.message,
  alertColor: state.notifications.color,
  alertTimeout: state.notifications.timeout,
  lang: state.i18nState.lang,
  currentUser: state.professionalAccount.currentUser,
  signedin: state.professionalAccount.signedin,
});
/**
 * Binds dispatch method to the actions passed as props to the component
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setLanguage: setLanguage,
      navigateTo: push,
      showClient,
      logout: accountActions.logout,
    },
    dispatch
  );

/**
 * List of languages supported
 */
const languages = {
  en: "English",
  de: "Deutsch",
};
/**
 * The navigation bar container. Renders the top bar with links to navigte in the site
 */
const Navigationbar = (props, context) => {
  /**
   * @constructor binds the toggle method and sets an isOpen boolean in the state
   * @param {} props
   */

  const [isOpen, setIsOpen] = useState(false);
  const { currentUser, showClient } = props;

  const clientId = REACT_APP_CLIENT_ID;
  const client = useSelector((state) => getClientAttributes(state, clientId));

  useEffect(() => {
    if (clientId) {
      showClient({ clientId });
    }
  }, [clientId, showClient]);

  const primaryColor = client?.primary_color;

  /** toggles the open state of the navbar */
  const toggle = () => {
    setIsOpen(!isOpen);
    // this.setState({
    //   isOpen: !this.state.isOpen
    // });
  };

  /** handles all navigation links */
  const handleNavigationAction = (path, event) => {
    event.preventDefault();
    props.navigateTo(path);
  };

  /** handles the change language event */
  const handleI18nAction = (lang, event) => {
    event.preventDefault();
    props.setLanguage(lang);
  };

  /** handles the click on logout */
  const handleLogoutAction = (event) => {
    event.preventDefault();
    const { currentUser } = props;
    props.logout(currentUser.tokens);
  };

  /** renders the user avatar in the navbar */
  const renderAvatar = () => {
    const { signedin, currentUser } = props;
    var avatarSource = avatar;
    var avatarRetinaSource = avatarRetina;
    var alt = "profile img placeholder";
    if (signedin && currentUser && currentUser.avatar) {
      alt = currentUser.name;
      if (currentUser.avatar !== "/images/avatar-placeholder.png") {
        avatarSource = currentUser.avatar;
        avatarRetinaSource = currentUser.avatar;
      }
    }
    return (
      <img
        src={avatarSource}
        width="38"
        height="38"
        data-src-retina={avatarRetinaSource}
        alt={alt}
        className="rounded-circle"
      />
    );
  };

  const { signedin } = props;
  const { alertMessage, alertColor, alertTimeout } = props;
  return (
    <div
      style={props.style}
      className={classNames("navigationbar ", {
        "navigationbar--sticky": props.isSticky,
      })}
    >
      <Navbar
        expand="sm"
        color={`${primaryColor ? primaryColor : "primary"}`}
        style={{ background: `${primaryColor ? primaryColor : ""}` }}
      >
        <img className="mr-2" src={ogLogo} width="50px" alt="og logo" />
        {client?.logo && (
          <img
            className="mx-3"
            src={client?.logo}
            width="50px"
            alt={client?.company_name}
          />
        )}
        <NavbarBrand
          className="p-1 ml-2"
          href="#"
          onClick={handleNavigationAction.bind(this, "/")}
          color={primaryColor}
        >
          <i className="fa fa-th mr-2" aria-hidden="true"></i>
          Dashboard
        </NavbarBrand>
        <NavbarToggler aria-label="navigation_toggle" onClick={toggle} />

        <Nav navbar className="navigationbar__user">
          <UncontrolledDropdown>
            <DropdownToggle nav>
              {signedin && (
                <span className="d-none d-lg-inline-block d-xl-inline-block">
                  {props.currentUser.email || props.currentUser.name}
                </span>
              )}
              {!signedin && (
                <span className="d-none d-lg-inline-block d-xl-inline-block">
                  Login / Signup
                </span>
              )}
              &nbsp;
              <i className="fa fa-angle-down" aria-hidden="true"></i>
              &nbsp;
              {renderAvatar()}
            </DropdownToggle>
            <DropdownMenu right>
              <AccessControl
                role={currentUser?.role}
                module="profile"
                right="index"
              >
                {signedin && (
                  <NavItem>
                    <NavLink href="/profile">
                      <i className="fa fa-user mr-1"></i>
                      {context.t("Profile")}
                    </NavLink>
                  </NavItem>
                )}
              </AccessControl>
              <AccessControl
                role={currentUser?.role}
                module="userManagement"
                right="index"
              >
                {signedin && (
                  <NavItem>
                    <NavLink href="/users">
                      <i className="fa fa-users"></i>&nbsp; {context.t("Users")}
                    </NavLink>
                  </NavItem>
                )}
              </AccessControl>
              <AccessControl
                role={currentUser?.role}
                module="locations"
                right="index"
              >
                {signedin && (
                  <NavItem>
                    <NavLink href="/locations">
                      <i className="fa fa-home"></i>&nbsp;{" "}
                      {context.t("Locations")}
                    </NavLink>
                  </NavItem>
                )}
              </AccessControl>
              {signedin && (
                <NavItem>
                  <NavLink href="#" onClick={handleLogoutAction.bind(this)}>
                    <i className="fa fa-sign-out"></i>&nbsp;{" "}
                    {context.t("Logout")}
                  </NavLink>
                </NavItem>
              )}
              {!signedin && (
                <NavItem>
                  <NavLink href="/account/login">
                    <i className="fa fa-user"></i>&nbsp; {context.t("Login")}
                  </NavLink>
                </NavItem>
              )}
              {!signedin && (
                <NavItem>
                  <NavLink href="/account/register">
                    <i className="fa fa-user-plus"></i>&nbsp;{" "}
                    {context.t("Register")}
                  </NavLink>
                </NavItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <i className="fa fa-language"></i>&nbsp;
                {languages[props.lang]}
              </DropdownToggle>
              <DropdownMenu right>
                {Object.keys(languages).map((language) => {
                  if (language !== props.lang) {
                    return (
                      <DropdownItem
                        key={language}
                        onClick={handleI18nAction.bind(this, language)}
                      >
                        {languages[language]}
                      </DropdownItem>
                    );
                  } else return "";
                })}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
      {alertMessage && (
        <FlashAlert color={alertColor || "success"} timeout={alertTimeout}>
          {alertMessage}
        </FlashAlert>
      )}
    </div>
  );
};

/**  define proptype for the 'translation' function  */
Navigationbar.contextTypes = {
  t: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigationbar);
