import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Page from "../components/Page";
import {
  getProfessionalUserAttributes,
  updateProfessionalUser,
  showProfessionalUser,
} from "../redux/professional_users";
import { listLocations } from "../redux/locations";
import { Jumbotron, Alert } from "reactstrap";

/**
 * UserEdit container, displays the user edit form
 */
const UserEdit = (props, context) => {
  const dispatch = useDispatch();
  const userId = props?.match?.params?.id;

  useEffect(() => {
    dispatch(showProfessionalUser(userId));
    dispatch(listLocations());
  }, [userId]);

  const locations = useSelector((state) => state.locations.list);

  const user = useSelector((state) =>
    getProfessionalUserAttributes(state, userId)
  );

  const currentUser = useSelector(
    (state) => state.professionalAccount.currentUser
  );

  //alert logic
  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  //update logic
  const [locationId, setLocationId] = useState(0);
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    dispatch(
      updateProfessionalUser({
        user: {
          name: data.name,
          nickname: data.nickname,
          email: data.email,
          role: role,
          client_id: user.client_id,
          location_id: locationId,
        },
        id: userId,
      })
    );
    window.scrollTo(0, 0);
    setTimeout(() => {
      setVisible(true);
    }, 500);
  };

  let roles = ["chef", "kitchen_assistant", "manager", "guest"];
  const [role, setRole] = useState("");

  const updateError = useSelector(
    (state) => state.professionalUsers.updateError
  );

  return (
    <div className="Dashboard">
      <Page id="dashboard" title="Edit User" noCrawl>
        <header className=" Dashboard-header bg-dark text-light"></header>
        {!updateError && (
          <Alert
            className="my-3"
            color="success"
            isOpen={visible}
            toggle={onDismiss}
          >
            {context.t("User updated successfully!")}
          </Alert>
        )}
        {updateError && (
          <Alert
            className="my-3"
            color="primary"
            isOpen={visible}
            toggle={onDismiss}
          >
            {context.t("There was an error processing your request.")}
          </Alert>
        )}
        <Link
          to={`/users/${userId}`}
          className="m-5"
          color="transparent"
          style={{ color: "#3ec7a5" }}
        >
          <i className="fa fa-chevron-left"></i> {context.t("Back")}
        </Link>
        <Jumbotron className="text-center p-2 d-flex justify-content-center">
          <h1 className="text-center Dashboard-title align-self-center">
            <i className="fa fa-pencil mr-2" aria-hidden="true"></i>
            {context.t("Edit User")}
          </h1>
          <form className="m-5 w-50" onSubmit={handleSubmit(onSubmit)}>
            <img
              className="center ingredient-detail-image mb-3"
              height="150px"
              width="150px"
              src={user?.image}
              alt={user?.name}
              style={{ border: "2px solid white" }}
            />
            <div>
              <label>NickName</label>
              <input
                className="form-control"
                {...register("nickname")}
                defaultValue={user?.nickname}
              />
              <label>Name</label>
              <input
                className="form-control"
                {...register("name")}
                defaultValue={user?.name}
              />
              {currentUser?.role === "manager" && (
                <>
                  <label>{context.t("Location")}</label>
                  <select
                    className="form-control"
                    {...register("location")}
                    onChange={(e) => setLocationId(parseInt(e.target.value))}
                    multiple
                  >
                    {locations.map((location, i) => (
                      <option
                        selected={i === user?.location_id - 1}
                        key={location?.id}
                      >
                        {location?.id} - {location?.attributes.name}
                      </option>
                    ))}
                  </select>
                  <label>{context.t("Role")}</label>
                  <select
                    className="form-control"
                    type="select"
                    {...register("role")}
                    multiple
                  >
                    {roles?.map((role, index) => (
                      <option
                        onClick={() => setRole(role)}
                        selected={role === user?.role}
                        key={index}
                      >
                        {role}
                      </option>
                    ))}
                  </select>
                </>
              )}

              <label>Email</label>
              <input
                className="form-control"
                type="email"
                {...register("email")}
                required
                defaultValue={user?.email}
              />
            </div>
            <div className="text-center m-2">
              <input
                type="submit"
                className="btn btn-danger btn-small"
                value="Edit"
              />
            </div>
          </form>
        </Jumbotron>
      </Page>
    </div>
  );
};

/**  define proptype for the 'translation' function  */
UserEdit.contextTypes = {
  t: PropTypes.func,
};

/** export the component as redux connected component */
export default UserEdit;
