/*
Replace
nestedRecipeIngredient with your resource Name :) Take care about different writings!
/!\ NestedRecipeIngredient nestedRecipeIngredient NestedRecipeIngredients nestedRecipeIngredients /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const nestedRecipeIngredientSlice = createSlice({
  name: "nestedRecipeIngredients",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
  },
});

export const {
  list: listNestedRecipeIngredients,
  show: showNestedRecipeIngredient,
  create: createNestedRecipeIngredient,
  update: updateNestedRecipeIngredient,
} = nestedRecipeIngredientSlice.actions;

export const actions = nestedRecipeIngredientSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const nestedRecipeIngredients = (state) =>
  state.nestedRecipeIngredients.idsList.map(
    (id) => state.nestedRecipeIngredients.dictionary[id]
  );
export const nestedRecipeIngredient = (state) =>
  state.nestedRecipeIngredients.showId &&
  state.nestedRecipeIngredients.dictionary[
    state.nestedRecipeIngredients.showId
  ];
export const nestedRecipeIngredientAttributes = (state) =>
  nestedRecipeIngredient(state)?.attributes;
export const nestedRecipeIngredientRelationships = (state) =>
  nestedRecipeIngredient(state)?.relationships;
export const nestedRecipeIngredientId = (state) =>
  state.nestedRecipeIngredients.showId;

export const getNestedRecipeIngredient = createSelector(
  (state) => state.nestedRecipeIngredients.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getNestedRecipeIngredientAttributes = createSelector(
  (state) => state.nestedRecipeIngredients.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getNestedRecipeIngredientRelationships = createSelector(
  (state) => state.nestedRecipeIngredients.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getNestedRecipeIngredients = createSelector(
  (state) => state.nestedRecipeIngredients.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export const getNestedRecipeIngredientIdsForDate = createSelector(
  (state) => state.nestedRecipeIngredients.dictionary,
  (_, day) => day,
  (dictionary, day) => {
    return Object.entries(dictionary)
      .filter(
        (_nestedRecipeIngredient) =>
          _nestedRecipeIngredient[1].attributes.date_of_serving === day
      )
      .map((_nestedRecipeIngredient) => _nestedRecipeIngredient[0]);
  }
);

export const getNestedRecipeIngredientIdsForDateAndTimeslot = createSelector(
  (state) => state.nestedRecipeIngredients.dictionary,
  (_, singleDayId) => singleDayId,
  (_, timeslotId) => timeslotId,
  (dictionary, { singleDayId, timeslotId }) => {
    return Object.entries(dictionary)
      .filter(
        (_nestedRecipeIngredient) =>
          _nestedRecipeIngredient[1].attributes.date_of_serving ===
            singleDayId &&
          _nestedRecipeIngredient[1].relationships.timeslot.data.id ===
            timeslotId
      )
      .map((_nestedRecipeIngredient) => _nestedRecipeIngredient[0]);
  }
);

export default nestedRecipeIngredientSlice.reducer;
