/**
 * The root saga function. This function is used to register all sagas watching the store
 */
import notificationsSaga from "./notifications/sagas";
import { all } from "redux-saga/effects";
import accountSaga from "./account/sagas";
import professionalAccountSagas from "./professional_account/sagas";
import dictionarySaga from "./dictionarySagas";
import serviceWorkerSaga from "./servWork/sagas";
import ingredientsSagas from "./ingredients/sagas";
import recipeIngredientsSagas from "./recipe_ingredients/sagas";
import dishRecipeIngredientsSagas from "./dish_recipe_ingredients/sagas";
import nestedRecipeIngredientsSagas from "./nested_recipe_ingredients/sagas";
import recipeStepIngredientsSagas from "./recipe_step_ingredients/sagas";
import recipesSagas from "./recipes/sagas";
import recipeStepsSagas from "./recipe_steps/sagas";
import allergensSagas from "./allergens/sagas";
import countersSagas from "./counters/sagas";
import articlesSagas from "./articles/sagas";
import suppliersSagas from "./suppliers/sagas";
import clientsSagas from "./clients/sagas";
import ingredientsGroupsSagas from "./ingredients_groups/sagas";
import nutrientsSagas from "./nutrients/sagas";
import locationsSagas from "./locations/sagas";
import techniquesSagas from "./techniques/sagas";
import timeslotsSagas from "./timeslots/sagas";
import techniquesStepsSagas from "./technique_steps/sagas";
import certificatesSagas from "./certificates/sagas";
import productsSagas from "./products/sagas";
import dishesSagas from "./dishes/sagas";
import menuDishesSagas from "./menu_dishes/sagas";
import usersSagas from "./users/sagas";
import coursesSagas from "./courses/sagas";
import lessonsSagas from "./lessons/sagas";
import lessonChaptersSagas from "./lesson_chapters/sagas";
import lessonChapterAnswersSagas from "./lesson_chapter_answers/sagas";
import professionalUsersSagas from "./professional_users/sagas";
import dishBundlesSagas from './dish_bundles/sagas';
import dishRecipeStepIngredientsSagas from './dish_recipe_step_ingredients/sagas';
// DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
// new scaffolded imports

/** exports the rootSaga for the store */
export default function* rootSaga() {
  yield all([
    notificationsSaga(),
    dictionarySaga(),
    accountSaga(),
    professionalAccountSagas(),
    serviceWorkerSaga(),
    ingredientsSagas(),
    recipeIngredientsSagas(),
    recipeStepIngredientsSagas(),
    recipesSagas(),
    recipeStepsSagas(),
    allergensSagas(),
    countersSagas(),
    dishRecipeIngredientsSagas(),
    articlesSagas(),
    ingredientsGroupsSagas(),
    suppliersSagas(),
    clientsSagas(),
    nutrientsSagas(),
    nestedRecipeIngredientsSagas(),
    locationsSagas(),
    techniquesSagas(),
    timeslotsSagas(),
    techniquesStepsSagas(),
    certificatesSagas(),
    productsSagas(),
    dishesSagas(),
    menuDishesSagas(),
    usersSagas(),
    coursesSagas(),
    lessonsSagas(),
    lessonChaptersSagas(),
    lessonChapterAnswersSagas(),
    professionalUsersSagas(),
    dishBundlesSagas(),
    dishRecipeStepIngredientsSagas(),
    // DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
    // new scaffolded sagas
  ]);
}
