import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import Page from "../components/Page";
import { Jumbotron, Alert } from "reactstrap";
import { createProfessionalUser } from "../redux/professional_users";
import { listLocations } from "../redux/locations";

/**
 * UserInvite container, invites a new user
 */
const UserInvite = (_props, context) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listLocations());
  }, []);

  //alert logic
  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  const { register, handleSubmit, reset } = useForm();

  const createError = useSelector(
    (state) => state.professionalUsers.createError
  );

  let roles = ["cook", "coaching", "client_manager", "unit_manager"];

  const [setRole] = useState("");
  const [setLocationId] = useState(0);
  const locations = useSelector((state) => state.locations.list);

  //submit logic
  const onSubmit = (data) => {
    dispatch(createProfessionalUser({
      user: {
        name: data.name,
        nickname: data.name,
        email: data.email,
        role: data.role,
        location_ids: data.locations,
      },
    }));
    reset({ email: "" });
    setTimeout(() => {
      setVisible(true);
    }, 500);
  };

  return (
    <div className="Dashboard">
      <Page id="dashboard" title="Invite User" noCrawl>
        <header className=" Dashboard-header bg-dark text-light"></header>
        {!createError && (
          <Alert
            className="my-3"
            color="success"
            isOpen={visible}
            toggle={onDismiss}
          >
            {context.t("Invitation sent successfully!")}
          </Alert>
        )}
        {createError && (
          <Alert
            className="my-3"
            color="primary"
            isOpen={visible}
            toggle={onDismiss}
          >
            {context.t(
              "There was an error processing your request or this user has already been invited."
            )}
          </Alert>
        )}
        <Link
          to={`/users`}
          className="m-5"
          color="transparent"
          style={{ color: "#3ec7a5" }}
        >
          <i className="fa fa-chevron-left"></i> {context.t("Back")}
        </Link>
        <Jumbotron className="text-center p-2 d-flex justify-content-center">
          <h1 className="text-center Dashboard-title align-self-center">
            <i className="fa fa-user-plus mr-2" aria-hidden="true"></i>
            {context.t("Invite User")}
          </h1>
          <form className="m-5 w-50" onSubmit={handleSubmit(onSubmit)}>
            <label>Name</label>
            <input
              className="form-control"
              type="name"
              {...register("name")}
              required
            />
            <label>Email</label>
            <input
              className="form-control"
              type="email"
              {...register("email")}
              required
            />
            <label>{context.t("Location")}</label>
            <select
              className="form-control"
              {...register("locations")}
              onChange={(e) => setLocationId(parseInt(e.target.value))}
              multiple
            >
              {locations.map((location, i) => (
                <option value={location?.id} key={location?.id}>
                  {location?.id} - {location?.attributes.name}
                </option>
              ))}
            </select>
            <label>{context.t("Role")}</label>
            <select
              className="form-control"
              type="select"
              {...register("role")}
            >
              {roles?.map((role, index) => (
                <option onClick={() => setRole(role)} key={index}>
                  {role}
                </option>
              ))}
            </select>

            <div className="text-center m-2">
              <input
                type="submit"
                className="btn btn-danger btn-small"
                value="Invite"
              />
            </div>
          </form>
        </Jumbotron>
      </Page>
    </div>
  );
};

/**  define proptype for the 'translation' function  */
UserInvite.contextTypes = {
  t: PropTypes.func,
};

/** export the component as redux connected component */
export default UserInvite;
