/**
 * The navbar container
 */
import React from "react";

import { connect } from "react-redux";
// import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import { setLanguage } from "redux-i18n";
import PropTypes from "prop-types";
import accountActions from "../../redux/professional_account";
/**
 * Selects props from the store and passes them to the component
 * @param {Object} state the store
 */
const mapStateToProps = (state) => ({
  alertMessage: state.notifications.message,
  alertColor: state.notifications.color,
  alertTimeout: state.notifications.timeout,
  lang: state.i18nState.lang,
  currentUser: state.professionalAccount.currentUser,
  signedin: state.professionalAccount.signedin,
});
/**
 * Binds dispatch method to the actions passed as props to the component
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setLanguage: setLanguage,
      navigateTo: push,
      logout: accountActions.logout,
    },
    dispatch
  );

const Footer = (props, context) => {
  /**
   * @constructor binds the toggle method and sets an isOpen boolean in the state
   * @param {} props
   */

  const currentYear = new Date().getFullYear();

  return (
    <div className="text-center pb-1 footer">
      <p>Copyright © {currentYear} | Organic Garden</p>
      <p>
        <a className="text-muted" href="https://www.organicgarden.de/impressum">
          Impressum |{" "}
        </a>
        <a
          className="text-dark"
          href="https://www.organicgarden.de/datenschutz-nutritionmanagement"
        >
          {context.t("Datenschutz")}
        </a>
      </p>
    </div>
  );
};

/**  define proptype for the 'translation' function  */
Footer.contextTypes = {
  t: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
