/*
Replace
dishRecipeIngredient with your resource Name :) Take care about different writings!
/!\ DishRecipeIngredient dishRecipeIngredient DishRecipeIngredients dishRecipeIngredients /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const dishRecipeIngredientSlice = createSlice({
  name: "dishRecipeIngredients",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
  },
});

export const {
  list: listDishRecipeIngredients,
  show: showDishRecipeIngredient,
  create: createDishRecipeIngredient,
  update: updateDishRecipeIngredient,
} = dishRecipeIngredientSlice.actions;

export const actions = dishRecipeIngredientSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const dishRecipeIngredients = (state) =>
  state.dishRecipeIngredients.idsList.map(
    (id) => state.dishRecipeIngredients.dictionary[id]
  );
export const dishRecipeIngredient = (state) =>
  state.dishRecipeIngredients.showId &&
  state.dishRecipeIngredients.dictionary[state.dishRecipeIngredients.showId];
export const dishRecipeIngredientAttributes = (state) =>
  dishRecipeIngredient(state)?.attributes;
export const dishRecipeIngredientRelationships = (state) =>
  dishRecipeIngredient(state)?.relationships;
export const dishRecipeIngredientId = (state) =>
  state.dishRecipeIngredients.showId;

export const getDishRecipeIngredient = createSelector(
  (state) => state.dishRecipeIngredients.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getDishRecipeIngredientAttributes = createSelector(
  (state) => state.dishRecipeIngredients.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getDishRecipeIngredientRelationships = createSelector(
  (state) => state.dishRecipeIngredients.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getDishRecipeIngredients = createSelector(
  (state) => state.dishRecipeIngredients.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export const getDishRecipeIngredientIdsForDate = createSelector(
  (state) => state.dishRecipeIngredients.dictionary,
  (_, day) => day,
  (dictionary, day) => {
    return Object.entries(dictionary)
      .filter(
        (_dishRecipeIngredient) =>
          _dishRecipeIngredient[1].attributes.date_of_serving === day
      )
      .map((_dishRecipeIngredient) => _dishRecipeIngredient[0]);
  }
);

export const getDishRecipeIngredientIdsForDateAndTimeslot = createSelector(
  (state) => state.dishRecipeIngredients.dictionary,
  (_, singleDayId) => singleDayId,
  (_, timeslotId) => timeslotId,
  (dictionary, { singleDayId, timeslotId }) => {
    return Object.entries(dictionary)
      .filter(
        (_dishRecipeIngredient) =>
          _dishRecipeIngredient[1].attributes.date_of_serving === singleDayId &&
          _dishRecipeIngredient[1].relationships.timeslot.data.id === timeslotId
      )
      .map((_dishRecipeIngredient) => _dishRecipeIngredient[0]);
  }
);

export default dishRecipeIngredientSlice.reducer;
