export const translations = {
  'de': {
    'Allergens': 'Allergene',
    'None': 'Keine',
    'This course currently has no lessons': 'Diese Kurse hat keine Lektion',
    'Monday': 'Montag',
    'Tuesday': 'Dienstag',
    'Wednesday': 'Mittwoch',
    'Thursday': 'Donnertag',
    'Friday': 'Freitag',
    'Saturday': 'Samstag',
    'Sunday': 'Sonntag',
    'Week': 'Woche',
    'Available Toppings:': 'Verfügbare Toppings:',
    'Edit Dish': 'Gericht bearbeiten',
    'Choose New Dish': 'Neues Gericht wählen',
    'Delete': 'Löschen',
    'Delete Dish': 'Gericht löschen',
    'Are you sure you want to delete this dish?': 'Bist du sicher, dass du dieses Gericht löschen willst?',
    'Cancel': 'Abbrechen',
    'Choose Dish': 'Gericht wählen',
    'Add a recipe +': 'Gericht hinzufügen +',
    'Choose Recipe': 'Rezept auswählen',
    'Export Shopping List': 'Einkaufsliste exportieren',
    'Datenschutz': 'Datenschutz',
    'Previous': 'Vorheriger Schritt',
    'Next': 'Nächster Schritt',
    'LoadingContent.message': 'Seite wird geladen',
    'Manager Contact': 'Kontakt Manager',
    'Plan Menu': 'Menü Planen',
    'Email': 'E-Mail',
    'required': 'Pflichtfeld',
    'Password': 'Passwort',
    'Login': 'Login',
    'Forgot your password? Recover': 'Passwort Vergessen',
    'Here': 'Hier',
    'Topping 1': 'Topping 1',
    'Topping 2': 'Topping 2',
    'Information': 'Informationen',
    'Profile': 'Profil',
    'Users': 'Users',
    'Locations': 'Standorte',
    'Logout': 'Logout',
    'Register': 'Anmelden',
    'Submit': 'Speichern',
    'Password (confirmation)': 'Passwort ist erfolgreich erstellt',
    'Basis': 'Basis',
    'Dish': 'Gericht',
    'Update': 'Aktualisieren',
    'Edit Servings': 'Portionen bearbeiten',
    'Ingredients': 'Zutaten',
    'No ingredients available': 'Keine Zutaten verfügbar',
    'Name': 'Name',
    'Season Warning {month}': 'Dieses Gericht enthält die folgenden Zutaten, die im {month} in der Nebensaison sind',
    'Season Confirmation {dish}': 'Möchten Sie eine {dish} planen?',
    'Plan Dish': 'Gericht planen',
    'Change password': 'Passwort ändern',
    'save': 'Speichern',
    'Unassigned': 'Nicht zugeordnet',
    'Back': 'Zurück',
    'Overview': 'Überblick',
    'Courses': 'Kurse',
    'Menu Planner': 'Menü Planer',
    'ExportShoppingList': 'Einkaufsliste exportieren',
    'This location doesn\'t have any counters yet': 'Diese Standort hat noch keinen Counter',
    'ProfessionalApp': 'Professional App',
    'User Management': 'Benutzerverwaltung',
    'Academy': 'Akademie',
    'Servings': 'Portionen',
    'Main Dish': 'Hauptgericht',
    'Start': 'Start',
    'Overview All Steps': 'Überblick alle Schritte',
    'Dish created successfully': 'Gericht erfolgreich erstellt',
    'Dish Name': 'Gericthsname',
    'Dish Description': 'Gerichtsbeschreibung',
    'Price': 'Preis',
    'FoodStatus': 'Ernährungsstatus',
    'HealthyFacts': 'Healthy Facts',
    'Extra': 'Extra',
    'Food & Nutrition Status': 'Ernährungsstatus',
    'Healthy Facts': 'Healthy Facts',
    'ForOFour.message': 'Seite wurde nicht gefunden',
    'ForOFour.button': 'Zurück',
    'Home': 'Home',
    'Home.header': 'Home',
    'Home.header.offline': 'Home',
    'You\'ve finished the lesson': 'Du hast die Lektion abgeschlossen',
    'You got': 'Du hast',
    'answers right': 'Frage(n) richtig beantwortet',
    'Back to Lessons': 'Zurück zu Lektionen',
    'Your Locations': 'Deine Standorte',
    'Dish Bundles': 'Menülinie',
    'Recipe Types': 'Ernährungsform',
    'Allergies': 'Allergien',
    'Sort By': 'Sortieren nach',
    'Search': 'Suche',
    'Your Menus': 'Deine Menüs',
    'Previous Step': 'Vorheriger Schritt',
    'Next Step': 'Nächster Schritt',
    'END OF RECIPE': 'REZEPT ENDE',
    'Edit': 'Bearbeiten',
    'Location': 'Standort',
    'Role': 'Rolle',
    'User updated successfully!': 'User erfolgreich aktualisiert!',
    'There was an error processing your request.': 'Es ist ein Fehler bei der Bearbeitung deiner Anfrage aufgetreten.',
    'Edit User': 'User bearbeiten',
    'Invitation sent successfully!': 'Einladung erfolgreich gesendet!',
    'There was an error processing your request or this user has already been invited.': 'Es ist ein Fehler bei der Bearbeitung deiner Anfrage aufgetreten oder dieser Benutzer wurde bereits eingeladen.',
    'Invite User': 'User einladen',
    'Your Users': 'Deine Users',
    'Nickname': 'Nickname',
  },
  'options': {
    'plural_rule': 'n != 1',
    'plural_number': '2',
  },
  'en': {
    'Allergens': '',
    'None': '',
    'This course currently has no lessons': '',
    'Week': '',
    'Available Toppings:': '',
    'Edit Dish': 'Edit Dish',
    'Choose New Dish': 'Choose New Dish',
    'Delete': 'Delete',
    'Delete Dish': 'Delete',
    'Are you sure you want to delete this dish?': '',
    'Cancel': '',
    'Choose Dish': 'Choose Dish',
    'Add a recipe +': '',
    'Choose Recipe': '',
    'Topping 1': '',
    'Topping 2': '',
    'Information': '',
    'Export Shopping List': '',
    'Datenschutz': '',
    'Previous': '',
    'Next': '',
    'LoadingContent.message': '',
    'Manager Contact': '',
    'Plan Menu': '',
    'Email': 'Email',
    'required': '',
    'Password': 'Password',
    'Login': 'Login',
    'Forgot your password? Recover': 'here',
    'Here': '',
    'Profile': '',
    'Users': '',
    'Locations': '',
    'Logout': 'Logout',
    'Register': 'Register',
    'Submit': 'Submit',
    'Password (confirmation)': 'Password Confirm',
    'Basis': '',
    'Dish': 'Dish',
    'Update': '',
    'Edit Servings': 'Edit Servings',
    'Ingredients': '',
    'No ingredients available': '',
    'Name': '',
    'Season Warning {month}': 'This dish contains the following ingredients that are in low season on {month}',
    'Season Confirmation {dish}': 'Do you want to plan {dish}?',
    'Plan Dish': '',
    'Change password': 'New Password',
    'save': '',
    'Unassigned': '',
    'Back': '',
    'Overview': '',
    'Courses': '',
    'Menu Planner': '',
    'ExportShoppingList': '',
    'This location doesn\'t have any counters yet': '',
    'ProfessionalApp': '',
    'User Management': 'User Management',
    'Academy': '',
    'Servings': '',
    'Main Dish': '',
    'Start': '',
    'Overview All Steps': '',
    'Dish created successfully': '',
    'Dish Name': '',
    'Dish Description': '',
    'Price': '',
    'FoodStatus': '',
    'HealthyFacts': '',
    'Extra': '',
    'Food & Nutrition Status': '',
    'Healthy Facts': '',
    'ForOFour.message': '',
    'ForOFour.button': '',
    'Home': '',
    'Home.header': 'Welcome To Bakerplate',
    'Home.header.offline': 'Welcome To Offline Bakerplate',
    'You\'ve finished the lesson': '',
    'You got': '',
    'answers right': '',
    'Back to Lessons': '',
    'Your Locations': '',
    'Dish Bundles': '',
    'Recipe Types': '',
    'Allergies': '',
    'Sort By': '',
    'Search': '',
    'Your Menus': '',
    'Previous Step': '',
    'Next Step': '',
    'END OF RECIPE': '',
    'Edit': '',
    'Location': '',
    'Role': '',
    'User updated successfully!': '',
    'There was an error processing your request.': '',
    'Edit User': '',
    'Invitation sent successfully!': '',
    'There was an error processing your request or this user has already been invited.': '',
    'Invite User': '',
    'Your Users': '',
    'Nickname': '',
  },
  'it': {
    'Name': '',
    'Description': '',
    'Submit': 'Invia',
    'Items.header': 'Ciao Bakerplate',
    'Items.header.offline': 'Ciao offline Bakerplate',
    'YYYY-MM-DD': 'DD.MM.YYYY',
    'LoadingContent.message': '',
    'Email': 'Email',
    'Password': 'Password',
    'ForgotPassword': 'Dimenticato le credenziali? Clicca',
    'ForgotPassword.here': 'qui',
    'NewPassword': 'Nuova Password',
    'NewPasswordConfirmation': 'Conferma Nuova Password',
    'PasswordConfirmation': 'Conferma Password',
    'Number': '',
    'Text': '',
    'TextArea': '',
    'checkbox': '',
    'Switch': '',
    'Male': '',
    'Female': '',
    'RadioGroup': '',
    'select': '',
    'multi-select': '',
    'ForOFour.message': '',
    'ForOFour.button': '',
    'Home.header': 'Ciao Bakerplate',
    'Home.header.offline': 'Ciao offline Bakerplate',
  },
}
